
import './App.css';
import './App.scss';
import { Hero } from './pages/Hero';


function App() {
  return (
    <>
    <Hero />
    </>
  );
}

export default App;
